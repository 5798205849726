module.exports = {
  navLinks: [
    {
      name: 'experience',
      url: '/#experience',
    },
    {
      name: 'contact',
      url: '/#contact',
    },
    {
      name: 'blog',
      url: '/blog',
    },
  ],
};
